<template>
    <div class="container">
        <b-modal title="Users" id="modal" no-close-on-esc hide-footer @hidden="model={}">
            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <div class="row">
                        <div class="col-md-12">
                            <validated-input name="Name" label="Name" v-model="model.name" :rules="rules.name"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Username" label="Username" v-model="model.username"
                                             :rules="rules.username"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Password" label="Password" type="password" v-model="model.password"
                                             v-if="!model.id" :rules="rules.password"/>
                        </div>
                        <div class="col-md-12 vue-select-dropdown mb-3">
                            <label>Role</label>
                            <b-form-select name="Role" label="Role" v-model="model.role"
                                           :rules="rules.role" :options="choices.role"></b-form-select>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Email" label="Email" v-model="model.email" :rules="rules.email"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Phone" label="Phone Number" v-model="model.phone"
                                             :rules="rules.phone" @keypress="isNumber"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Whatsapp Number" label="Whatsapp Number"
                                             v-model="model.whatsapp_number"
                                             :rules="rules.whatsapp_number" @keypress="isNumber"/>
                        </div>

                        <div class="col-md-12">
                            <b-button type="submit" variant="success">
                                Save
                            </b-button>
                        </div>

                    </div>
                </b-form>
            </validation-observer>
        </b-modal>

        <b-card class="mt-4">
            <b-card-title>
                <b-button variant="success" size="sm" class="float-right" @click="openModal">New User</b-button>
                <h3>Users</h3>
            </b-card-title>
            <vue-table :data="data" :fields="fields" :url="url" ref="table">
                <template #action="{rowIndex, rowData}">
                    <b-button-group>
                        <b-button size="sm" variant="primary" @click="editItem(rowData)">Edit</b-button>
                        <b-button size="sm" variant="danger" @click="deleteItem(rowData)">Delete</b-button>
                        <b-button size="sm" variant="warning" @click="viewItem(rowData)">Details</b-button>
                    </b-button-group>
                </template>
            </vue-table>
        </b-card>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'secure-axios';
import urls from '@data/urls';

export default {
    name: 'Users',
    components: {},
    data () {
        return {
            choices: {
                role: []
            },
            model: {
                username: '',
                password: '',
                email: '',
                phone: '',
                whatsapp_number: '',
                name: '',
                role: ''
            },
            rules: {
                username: {
                    required: true
                },
                password: {
                    required: true
                },
                email: {
                    required: true
                },
                phone: {
                    required: true
                },
                whatsapp_number: {
                    required: true
                },
                name: {
                    required: true
                },
                role: {
                    required: true
                }
            },
            data: [],
            url: urls.users.list,
            fields: [
                {
                    name: 'id',
                    title: 'ID'
                },
                {
                    name: 'username',
                    title: 'Username'
                },
                {
                    name: 'name',
                    title: 'Name'
                },
                {
                    name: 'email',
                    title: 'Email'
                },
                {
                    name: 'phone',
                    title: 'Phone'
                },
                {
                    name: 'role.text',
                    title: 'Role'
                },
                {
                    name: '__slot:action',
                    title: 'Action'
                }
            ]
        };
    },

    beforeMount () {
        this.loadRoleData();
    },

    methods: {
        openModal () {
            this.$bvModal.show('modal');
        },

        setData (response) {
            console.log('response.data', response);
            this.$refs.table.refreshTable();
        },

        async onSubmit () {
            let url;
            if (this.model.id) {
                url = urls.users.update;
            } else {
                url = urls.users.create;
            }

            const response = await axios.form(url, this.model);
            this.setData(response);
            this.$bvModal.hide('modal');
        },
        async loadRoleData () {
            const response = await axios.get(urls.userRoles.dropdownlist);
            this.choices.role = response.data.Role;
            console.log('UserRoles', this.choices.roles);
        },

        async editItem (item) {
            console.log('item', item);
            this.model = {
                id: item.id,
                username: item.username,
                email: item.email,
                phone: item.phone,
                name: item.name,
                role: item.role.value,
                whatsapp_number: item.whatsapp_number
            };
            this.openModal();
        },

        async deleteItem (rowData) {
            const confirm = await this.$bvModal.msgBoxConfirm('Are you sure ?');
            if (confirm) {
                const params = { id: rowData.id };
                const response = await axios.form(urls.users.delete, params);
                this.setData(response);
            }
        },
        async viewItem (rowData) {
            const id = rowData.id;
            console.log('id', id);
            console.log('index', rowData);
            this.$router.push({ path: '/admin/user/' + id + '/details' });
        },

        isNumber: function (evt) {
            evt = (evt) || window.event;
            const charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
                ;
            } else {
                return true;
            }
        }
    }
};

</script>
